import { useEffect, useState } from "react";
import * as Styled from "./styled";
import Clock from "../../components/time";
import DateDisplay from "../../components/date";
import CellService from "../../services/cell.service";
import { parseError } from "../../config/error-handler";
import GraphTV from "../../components/graficoTV";
import RowFieldTVGrafico from "../../parts/RowFieldTVGrafico";
import ProductionDto from "../../dto/production.dto";

// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const TvGrafico = () => {

  const cellService = new CellService();
  const percentualAtualMeta = '50';
  const [funcionario, setFuncionario] = useState('');
  const [meta, setMeta] = useState(0);
  const [produzidos, setProduzidos] = useState(0);
  const [nc, setNc] = useState(0);
  const [postoTrabalho, setPostoTrabalho] = useState(1);
  const [celula, setCelula] = useState(1);
  const [tvData, setTvData] = useState<ProductionDto[]>([{
    name: "",
    amount: 0,
    meta: 0,
    metaMovel: 0,
  }])
  
  useEffect(() => {
    const startDay : Date = new Date()
    startDay.setHours(0,1,0,0)

    cellService
      .getTvData(celula, startDay)
      .then(data => setTvData(data))
      .catch(parseError)

    const interval = setInterval(() => 
      cellService
      .getTvData(celula, startDay)
      .then(response => setTvData(response))
      .catch(parseError), 30000);
    
    return () => clearInterval(interval);
      }, []);

  return (
    <Styled.ContainerTV>
      <Styled.RowDataHora>
        <Styled.Left>
          <DateDisplay />
        </Styled.Left>
        <Styled.Right>
          <Clock />
        </Styled.Right>
      </Styled.RowDataHora>
      <Styled.Card>
        <Styled.Row style={{ justifyContent: 'center' }}>
          <RowFieldTVGrafico label={'Acompanhamento da Meta - Célula 0'} value={celula.toString()} />
        </Styled.Row>
        <Styled.RowGraph>
          <GraphTV infos={tvData}/>
        </Styled.RowGraph>
      </Styled.Card>
  </Styled.ContainerTV>
  );
};

export default TvGrafico;