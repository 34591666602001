import React, { useEffect, useRef } from 'react';
import Chart, { ChartData, ChartTypeRegistry } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as Styled from "./styled";
import ProductionDto from '../../dto/production.dto';

interface BarChartProps{
  infos: ProductionDto[];
  corBarraLoading ?: string;
}

const BarChart = ({infos}: BarChartProps) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart | null>(null);
  let produzindo : number = 0;

  const maxValue = () => {
    const total = infos[0]?.meta + produzindo
    return total 
  }

  useEffect(() => {
    if (!chartRef.current) return;

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const ctx = chartRef.current.getContext('2d');

    if (!ctx) return;
    
    const data: ChartData<keyof ChartTypeRegistry, number[], string> = {
      labels: infos.map(value => value.name.toString()),
      datasets: [
        {
          label: '100% da Meta',
          data: infos.map(_ => infos[0].meta),
          borderColor: '#39DD00',
          type: 'line',
          fill: false,
          borderDash: [8, 8], // Padrão tracejado com 8px de traço e 8px de espaço
          borderWidth: 7,
          yAxisID: 'y3',
        },
        {
          label: 'Meta',
          data: infos.map(value => {
            produzindo += value.amount;
            return produzindo
          }),
          borderColor: '#277EFF',
          type: 'line',
          fill: false,
          borderWidth: 3,
          yAxisID: 'y',
        },
        {
          label: 'Meta - móvel',
          data: infos.map(value => {
            return value.metaMovel
          }),
          borderColor: '#3ac22e',
          type: 'line',
          fill: false,
          borderWidth: 3,
          yAxisID: 'y4',
        },
        {
          label: 'Produzido',
          data: infos.map(value => value.amount),
          backgroundColor: '#80764B',
          type: 'bar',
          barPercentage: 0.5,
          barThickness: 40,
          yAxisID: 'y1',
        },
      ],
    };

    chartInstance.current = new Chart(ctx, {
      type: 'bar',
      data: data,
      options: {
        scales: {
          x: {
            type: 'category',
            position: 'bottom',
            ticks: {
              color: '#ffffff',
              font: {
                family: 'Lato',
                size: 15,
                weight: 600,
              },
            },
          },
          // Grafico Barras
          y1: {
            display: false,
            beginAtZero: true,
            max: maxValue(),
            min: 0,
            stacked: true,
            ticks: {
              color: '#ffffff',
              font: {
                family: 'Lato',
                size: 12,
                weight: 500,
              },
            },
          },

          // Grafico Linha Azul
          y: {
            display: true,
            beginAtZero: true,
            max: maxValue(),
          },
          // Grafico Linha Y=0
          y2: {
            display: false,
            beginAtZero: true,
            max: maxValue(),
          },
          // Grafico Linha Y=100
          y3: {
            display: false,
            beginAtZero: true,
            max: maxValue(),
            min: 0,
          },
          y4: {
            display: false,
            beginAtZero: true,
            max: maxValue(),
            ticks: {
              color: '#80764B',
              font: {
                family: 'Lato',
                size: 12,
                weight: 500,
              },
            },
          },
        },
        plugins: {
          datalabels: {
            display: false,
            color: '#ffffff', 
            anchor: 'end',
            align: 'top',
            offset: -40,
              font: {
                family: 'Lato',
              size: 20,
                weight: 500,
              },
            formatter: (value, context) => {
              if (value === 0 || value === 100) {
                return '';
              }
              return value;
            },
          },
          legend: {
            display: false,
          },
        },
        elements: {
          bar: {
            borderRadius: 2,
          },
          line: {
            fill: false,
            tension: 0.1,
          },
          point: {
            radius: 0,
          },
        },
      },
      plugins: [ChartDataLabels],
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  });

  return (
    <Styled.ChartContainer>
      <Styled.ContainerRow>
        <canvas ref={chartRef}> </canvas>
      </Styled.ContainerRow>
    </Styled.ChartContainer>
  );
};

export default BarChart;
