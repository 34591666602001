// Tv.js
import { useEffect, useRef, useState } from 'react';
import TvFabricaComponent from '../tvFabrica';
import TvGraficoComponent from '../tvGrafico';
import * as Celula from "../tvCelula/styled"
import DateDisplay from '../../components/date';
import Clock from '../../components/time';
import RowFieldTV from "../../parts/RowFiedlTV";
import RowFieldTVBold from "../../parts/RowFieldTVBold/RowFieldTVBold";
import RowFieldTVIcon from "../../parts/RowFieldTVIcon";
import ProgressBarTV from "../../components/progressBarTV";
import LogoTVCelula from "../../components/LogoTVCelula";
import CardTitleTV from "../../parts/CardTitleTV";
import ProductionCellDto from '../../dto/production-cell.dto';
import CellService from '../../services/cell.service';
import { parseError } from '../../config/error-handler';

const cellsService = new CellService()

const TvFabrica = () => {
  return <TvFabricaComponent />;
};
interface TvCelulaProps{
  id:number;
  onFetch : () => void;
}

const TvCelula = ({id, onFetch}:TvCelulaProps) => {
  const [cell, setCell] = useState<ProductionCellDto>({
    id: 0,
    goal: 0,
    production: 0,
    nc: 0,
    workedHours: 0,
    notWorkedHours: "",
    productionType: "",
    title: "",
    workstations: []
  })
  const finalTurno = "17:30"
  const percentualProducaoCelula = cell.goal !== 0 ? (cell.production / cell.goal) * 100 : 0;

  const hasMounted = useRef(false); // ref para controlar montagem

  useEffect(() => {
    cellsService
      .getCell(id)
      .then((response) => {
        setCell(response);
      })
      .catch(parseError);
  }, []);

  // Executa o onFetch somente na desmontagem do componente
  useEffect(() => {
    if (hasMounted.current) {
      return () => {
        onFetch();
      };
    } else {
      hasMounted.current = true; // Marca como montado após o primeiro render
    }
  }, []);

 return (
    <Celula.ContainerTV>
      <Celula.RowDataHora>
        <Celula.Left>
          <DateDisplay />
        </Celula.Left>
        <Celula.Right>
          <Clock />
        </Celula.Right>
      </Celula.RowDataHora>
      <Celula.Card>
        <Celula.Row>
          <CardTitleTV title={'Célula ' + cell.id} />
        </Celula.Row>
        <Celula.Row>
          <Celula.Left>
            <ProgressBarTV percentualAtualMeta={percentualProducaoCelula} corBarraLoading="#007bff"/>
            <RowFieldTVBold label={'Meta da Célula'} value={cell.goal + ' unidades'} />
            <RowFieldTV label={'Produção Total'} value={cell.production + ' unidades'} />
            <RowFieldTV label={'Produção N/C'} value={cell.nc + ' unidades'} />
          </Celula.Left>
          <Celula.Right>
            <RowFieldTVIcon label={'Horas Trabalhadas'} value={cell.workedHours.toString()} />
            <RowFieldTVIcon label={'Final do Turno'} value={finalTurno} />
            <LogoTVCelula />
          </Celula.Right>
        </Celula.Row>
      </Celula.Card>
  </Celula.ContainerTV>
  );
};

const Tv = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [numCells, setNumCells] = useState<number[]>([])
  const [index, setIndex] = useState<number>(0)
  const totalPagesCelula = 2; //devera ser setado via backend

  useEffect(() => {
    cellsService
      .getNumCells()
      .then(response => {
        setNumCells(response)
      })
      .catch(parseError)
    
    const interval = setInterval(() => {
      setCurrentPage(prevPage => (prevPage % totalPagesCelula) + 1)
    }, 5000); 
  
    return () => clearInterval(interval);
  }, []);

  const onFetch = () => {
    if(index === numCells.length - 1){
      cellsService
        .getNumCells()
        .then(response => {
          setNumCells(response)
          setIndex(0)
        })
        .catch(parseError)
    }else{
      setIndex((prevIndex) => (prevIndex + 1));
    }
    console.log("OnFetch foi rodado")
  };

  return (
    <div>
      {currentPage === 1 ? <TvFabrica /> : null}
      {currentPage === 2 ? <TvCelula id={numCells[index]} onFetch={onFetch}/> : null}
    </div>
  );
};

export default Tv;
