import { SetStateAction, useEffect, useState } from "react";
import * as Styled from "./styled";
import Clock from "../../components/time";
import DateDisplay from "../../components/date";
import RowFieldTV from "../../parts/RowFiedlTV";
import RowFieldTVBold from "../../parts/RowFieldTVBold/RowFieldTVBold";
import ProgressBarTV from "../../components/progressBarTV";
import CardTitleTV from "../../parts/CardTitleTV";
import LogoTVCelula from "../../components/LogoTVCelula";
import DataFactoryService from "../../services/datafactory.service";
import { parseError } from "../../config/error-handler";
import LogoTV from "../../components/LogoTV";

// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const TvFabrica = () => {

  // Valor que representa o progresso da barra de loading
  //    -  meta da fábrica / produzidos da fábrica
  const [progress, setProgress] = useState(0);

  // Dados da fábrica
  const dataFactory = new DataFactoryService();
  const [metaFabrica, setMetaFabrica] = useState(0);
  const [produzidosFabrica, setProduzidosFabrica] = useState(0);
  const [ncFabrica, setNcFabrica] = useState(0);
  // end Dados da fábrica

  // Calculo do percentual de produção da fábrica
  const percentualAtualMeta = metaFabrica !== 0 ? ((produzidosFabrica / metaFabrica) * 100).toFixed(2) : 0.0.toFixed(2);

  useEffect(() => {
    dataFactory
      .getDataFactories()
      .then((response: { totalGoal: SetStateAction<number>; totalUnitsProduced: SetStateAction<number>; totalUnitsProducedNc: SetStateAction<number>; }) => {
        setMetaFabrica(response.totalGoal);
        setProduzidosFabrica(response.totalUnitsProduced);
        setNcFabrica(response.totalUnitsProducedNc);
      })
      .catch(parseError);

    const interval = setInterval(() => {
      dataFactory
      .getDataFactories()
      .then((response: { totalGoal: SetStateAction<number>; totalUnitsProduced: SetStateAction<number>; totalUnitsProducedNc: SetStateAction<number>; }) => {
        setMetaFabrica(response.totalGoal);
        setProduzidosFabrica(response.totalUnitsProduced);
        setNcFabrica(response.totalUnitsProducedNc);
      })
      .catch(parseError);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  
  return (
    <Styled.ContainerTV>
      <Styled.RowDataHora>
        <Styled.Left>
          <DateDisplay />
        </Styled.Left>
        <Styled.Right>
          <Clock />
        </Styled.Right>
      </Styled.RowDataHora>
      <Styled.Card>
        <Styled.Row>
            <CardTitleTV title={'Fábrica'} />
        </Styled.Row>
        <Styled.Row>
          <Styled.Left>
            <ProgressBarTV percentualAtualMeta={parseFloat(percentualAtualMeta)} corBarraLoading="#378E18" />
            <RowFieldTVBold label={'Meta da Fábrica'} value={metaFabrica + ' unidades'} />
            <RowFieldTV label={'Produção Total'} value={produzidosFabrica + ' unidades'} />
            <RowFieldTV label={'Produção N/C'} value={ncFabrica + ' unidades'} />
          </Styled.Left>
          <Styled.Right>
            <LogoTV />
          </Styled.Right>
        </Styled.Row>
      </Styled.Card>
  </Styled.ContainerTV>
  );
};

export default TvFabrica;