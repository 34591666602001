import { useEffect, useState } from "react";
import * as Styled from "./styled";
import Clock from "../../components/time";
import DateDisplay from "../../components/date";
import CellService from "../../services/cell.service";
import { parseError } from "../../config/error-handler";
import RowFieldTV from "../../parts/RowFiedlTV";
import RowFieldTVBold from "../../parts/RowFieldTVBold/RowFieldTVBold";
import RowFieldTVIcon from "../../parts/RowFieldTVIcon";
import ProgressBarTV from "../../components/progressBarTV";
import LogoTVCelula from "../../components/LogoTVCelula";
import CardTitleTV from "../../parts/CardTitleTV";

// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const TvCelula = () => {

  const cellService = new CellService();
  const [progress, setProgress] = useState(0);
  const [meta, setMeta] = useState(0);
  const [produzidos, setProduzidos] = useState(0);
  const [nc, setNc] = useState(0);
  const [postoTrabalho, setPostoTrabalho] = useState(1);
  const [celula, setCelula] = useState(1);
  const [index, setIndex] = useState(1);
  const [horasTrabalhadas, setHorasTrabalhadas] = useState('0h00');
  const [numCells, setNumCells] = useState<number[]>([])
  
  // Constante de Horário final de turno
  const finalTurno = '17h30';

  // Calculo percentual de produção da célula
  const percentualProducaoCelula = meta !== 0 ? (produzidos / meta) * 100 : 0;

  useEffect(() => {
    cellService
      .getNumCells()
      .then(response => {
        setNumCells(response);
        cellService.getCell(response[0]).then(response => {
          setMeta(response.goal)
          setProduzidos(response.production)
          setNc(response.nc)
          setHorasTrabalhadas(response.workedHours.toString())
          setCelula(response.id)
        }).catch(parseError);
      })
      .catch(parseError)
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if(index === (numCells.length - 1)){
        cellService.getNumCells().then(response => {
          cellService
            .getCell(numCells[index])
            .then(response => {
            setMeta(response.goal)
            setProduzidos(response.production)
            setNc(response.nc)
            setHorasTrabalhadas(response.workedHours.toString())
            setCelula(response.id)
        }).catch(parseError)

        setNumCells(response)
        })

      setIndex(0)
      }else{
        cellService
        .getCell(numCells[index])
        .then(response => {
          setMeta(response.goal)
          setProduzidos(response.production)
          setNc(response.nc)
          setHorasTrabalhadas(response.workedHours.toString())
          setCelula(response.id)
        }).catch(parseError)
      
      setIndex(prev => prev + 1)
      }
    }, 10000)

    return () => clearInterval(interval);
  })
    
  return (
    <Styled.ContainerTV>
      <Styled.RowDataHora>
        <Styled.Left>
          <DateDisplay />
        </Styled.Left>
        <Styled.Right>
          <Clock />
        </Styled.Right>
      </Styled.RowDataHora>
      <Styled.Card>
        <Styled.Row>
          <CardTitleTV title={'Célula ' + celula.toString()} />
        </Styled.Row>
        <Styled.Row>
          <Styled.Left>
            <ProgressBarTV percentualAtualMeta={percentualProducaoCelula} corBarraLoading="#007bff"/>
            <RowFieldTVBold label={'Meta da Célula'} value={meta + ' unidades'} />
            <RowFieldTV label={'Produção Total'} value={produzidos + ' unidades'} />
            <RowFieldTV label={'Produção N/C'} value={nc + ' unidades'} />
          </Styled.Left>
          <Styled.Right>
            <RowFieldTVIcon label={'Horas Trabalhadas'} value={horasTrabalhadas} />
            <RowFieldTVIcon label={'Final do Turno'} value={finalTurno} />
            <LogoTVCelula />
          </Styled.Right>
        </Styled.Row>
      </Styled.Card>
  </Styled.ContainerTV>
  );
};

export default TvCelula;