import styled from 'styled-components';

export const ChartContainer = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  height: 100%;
  
  canvas {
    flex-shrink: 1;
    flex-basis: 1;
    border-radius: 10px;
    border: 4px solid #212121;
    background-color: transparent;
    width: 100% !important;
    
  } 
`;

export const ContainerRow = styled.div`
  height: 650px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('assets/images/svg/LogoViaAromaGrafico.svg');
  background-repeat: no-repeat;
  background-position: center;
`;